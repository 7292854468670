import React from "react"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'
import Layout from "../layouts"
import Header from "../components/header/header";
import { bannerEvr, headerEvr } from "../utils/generalInformation";
import Banner from "../components/banner/banner";


const Everton = () => {


  return (
    <Layout
      SEOTitle={"Everton Football Club - United Legal Assistance"}
      SEODescription={"Our Everton Football Club Hospitality: Fancy coming to the game? We're paying! Can we count you in? Would you like more information about our Everton Football Club Hospitality?"}
      footerDisclaimer={""}>

      <Header props={headerEvr} />

      <Banner props={bannerEvr} />

    </Layout>
  )

}

export default Everton